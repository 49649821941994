import {ChangeEvent, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex, Grid, GridItem} from '@chakra-ui/react';
import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {JsForm} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {Header} from 'ui-kit/headers';
import {ProjectTypeEnum} from 'api';

export const ProjectSceneFC = () => {
  const {authStore} = useStore();

  const [schema, setSchema] = useState<RJSFSchema>();
  const [uiSchema, setUiSchema] = useState<UiSchema>();
  const [data, setData] = useState<object>();

  const fileRef = useRef<HTMLInputElement>(null);

  const getJsonFileValid = (files: FileList | null) => {
    return !!files && !!files[0] && files[0].type === 'application/json' ? files[0] : null;
  };

  const readFileAsJson = (file: File, callback: (json: object) => void) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = (e) => {
      callback(JSON.parse(e.target?.result as string) as object);
    };
  };

  const onChangeSchemaFile = (e: ChangeEvent<HTMLInputElement>) => {
    const jsonFile = getJsonFileValid(e.target.files);
    if (jsonFile) {
      readFileAsJson(jsonFile, (json: object) => setSchema(json));
    } else {
      setSchema(undefined);
    }
  };

  const onChangeUiSchemaFile = (e: ChangeEvent<HTMLInputElement>) => {
    const jsonFile = getJsonFileValid(e.target.files);
    if (jsonFile) {
      readFileAsJson(jsonFile, (json: object) => setUiSchema(json));
    } else {
      setUiSchema(undefined);
    }
  };

  const onChangeDataFile = (e: ChangeEvent<HTMLInputElement>) => {
    const jsonFile = getJsonFileValid(e.target.files);
    if (jsonFile) {
      readFileAsJson(jsonFile, (json: object) => setData(json));
    } else {
      setData(undefined);
    }
  };

  return (
    <Box>
      {authStore.user && (
        <Header
          project={{
            uuid: '',
            name: 'Валидация JsonForm-схем',
            type: ProjectTypeEnum.UnknownDefaultOpenApi,
            createdAt: new Date(),
            updatedAt: new Date(),
            deleted: false,
            isFavorite: false,
            checkpoints: []
          }}
          onExport={() => Promise.resolve()}
          onRestore={() => Promise.resolve()}
          onRename={() => Promise.resolve()}
          onDelete={() => Promise.resolve()}
        />
      )}

      <Grid
        position="relative"
        h="var(--height-layout)"
        gridTemplateColumns={'48px 280px 440px 1fr'}
      >
        <GridItem bg="bg" borderRight="1px" borderColor="border" />

        {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS ... */}
        <GridItem bg="bg" borderRight="1px" borderColor="border" p="10px">
          <Flex gap="10px" direction="column">
            <Flex
              p="10px"
              gap="4px"
              direction="column"
              border="1px dashed"
              borderColor={schema ? 'success' : 'border'}
            >
              <Box color={schema ? 'success' : 'bodyText'}>Схема</Box>
              <input type="file" ref={fileRef} onChange={onChangeSchemaFile} />
            </Flex>

            <Flex
              p="10px"
              gap="4px"
              direction="column"
              border="1px dashed"
              borderColor={uiSchema ? 'success' : 'border'}
            >
              <Box color={uiSchema ? 'success' : 'bodyText'}>Ui-схема</Box>
              <input type="file" ref={fileRef} onChange={onChangeUiSchemaFile} />
            </Flex>

            <Flex
              p="10px"
              gap="4px"
              direction="column"
              border="1px dashed"
              borderColor={data ? 'success' : 'border'}
            >
              <Box color={data ? 'success' : 'bodyText'}>Данные</Box>
              <input type="file" ref={fileRef} onChange={onChangeDataFile} />
            </Flex>
          </Flex>
        </GridItem>

        {/* SELECTED PROJECT ITEM */}
        <GridItem bg="bg" borderRight="1px" borderColor="border" p="0 8px" overflowY="auto">
          {!!schema && !!uiSchema && !!data && (
            <JsForm
              formData={data}
              schema={schema}
              uiSchema={uiSchema}
              onChange={(formData) => console.info('FormData:', formData)}
            />
          )}
        </GridItem>

        {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
        <GridItem bg="bg" />
      </Grid>
    </Box>
  );
};

export const SystemScene = observer(ProjectSceneFC);

export default SystemScene;
