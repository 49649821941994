import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Text, Center} from '@chakra-ui/react';
import {
  ElementByRFElement,
  ElementIcon,
  ERFElement,
  getNextNodePosition
} from '@progress-fe/rf-core';
import {EElement, useT} from '@progress-fe/core';
import {XYPosition, Node} from '@xyflow/react';
import {
  Loader,
  useDialog,
  ISvgMenuItem,
  ItemListHeader,
  IEntityErrorMessage
} from '@progress-fe/ui-kit';

import {StructureItem} from 'ui-kit';
import {EActionType} from 'core/enums';
import {TElementDetailsModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  selectedId: string | null;
  selectedSubId: string | null;
  nodes: Array<Node>;
  elements: TElementDetailsModel[];
  menuItems: ISvgMenuItem<string>[];
  errors: Array<IEntityErrorMessage>;
  onSelect: (elementId: string, subElement?: string) => void;
  onCreate: (type: EElement, position: XYPosition) => Promise<void>;
  onDelete: (elementIds: Array<string>) => Promise<void>;
}

const ElementsListFC: FC<IProps> = ({
  isLoading,
  selectedId,
  selectedSubId,
  elements,
  nodes,
  errors,
  menuItems,
  onSelect,
  onCreate,
  onDelete
}) => {
  const {t} = useT();

  const DeleteDialog = useDialog({title: t('elements.delete')});

  const elementMenuItems: ISvgMenuItem<EActionType>[] = [
    {id: EActionType.Download, name: t('actions.download'), isDisabled: true},
    {id: EActionType.Duplicate, name: t('actions.duplicate'), isDisabled: true},
    {id: EActionType.Delete, name: t('actions.delete')}
  ];

  const handleOnMenuAction = (uuid: string, action: EActionType) => {
    const element = elements.find((el) => el.uuid === uuid);
    if (action === EActionType.Delete) {
      DeleteDialog.open(t('elements.deleteSureMsg', {name: element?.name}), {
        apply: {title: t('actions.delete'), isDanger: true, onClick: () => onDelete([uuid])},
        close: {title: t('actions.cancel')}
      });
    }
  };

  const handleOnCreateElement = async (elementTypeAsString: string) => {
    const type = ElementByRFElement[elementTypeAsString as ERFElement];
    if (type) {
      const nextPosition = getNextNodePosition(nodes);
      await onCreate(type, nextPosition);
    }
  };

  return (
    <Box overflowY="hidden">
      <DeleteDialog.Render />
      <ItemListHeader
        menuItems={menuItems}
        title={t('elements.elements')}
        onMenuSelect={handleOnCreateElement}
      />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            {elements.map((el) => {
              return (
                <StructureItem
                  key={el.uuid}
                  item={{
                    uuid: el.uuid,
                    name: el.name,
                    suffix: t(`enum.element.${el.type}`),
                    elementIcon: el.type ? ElementIcon[el.type] : undefined,
                    isActive: selectedId === el.uuid && !selectedSubId,
                    isError: errors.some((e) => e.entityUuid === el.uuid),
                    isDeletable: el.deletable
                  }}
                  subItems={el.subElements.map((subEl) => ({
                    uuid: subEl.uuid,
                    name: subEl.name,
                    suffix: t(`enum.element.${subEl.type}`),
                    elementIcon: subEl.type ? ElementIcon[subEl.type] : undefined,
                    isError: errors.some((e) => e.entityUuid === subEl.uuid),
                    isActive: selectedSubId === subEl.uuid,
                    isDeletable: subEl.deletable
                  }))}
                  menuItems={elementMenuItems}
                  onMenuAction={(action) => handleOnMenuAction(el.uuid, action)}
                  onClick={onSelect}
                />
              );
            })}

            {elements.length === 0 && (
              <Center pb="40px" height="100%">
                <Text>{t('elements.noElements')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const ElementList = observer(ElementsListFC);
