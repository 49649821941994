import {FC, PropsWithChildren, useState} from 'react';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';
import {useT} from '@progress-fe/core';
import {Box, Flex, Center} from '@chakra-ui/react';
import {ISvgMenuItem, Svg, SvgMenu} from '@progress-fe/ui-kit';

import {ProjectOut} from 'api';
import {ROUTES} from 'core/constants';
import {EActionType} from 'core/enums';
import {ProjectRenameModal, ProjectDeleteModal} from 'ui-kit';

interface IProps extends PropsWithChildren {
  project: ProjectOut;
  onRename: (newName: string) => Promise<void>;
  onExport: () => Promise<void>;
  onRestore: () => Promise<void>;
  onDelete: () => Promise<void>;
}

const HeaderFC: FC<IProps> = ({project, children, onRename, onExport, onRestore, onDelete}) => {
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const {t} = useT();
  const navigate = useNavigate();

  const isInBin = project.deleted;

  const menuItems: ISvgMenuItem<EActionType>[] = [];
  menuItems.push({id: EActionType.Rename, name: t('actions.rename'), icon: 'Pencil'});
  menuItems.push({id: EActionType.Download, name: t('actions.download'), icon: 'Export'});
  if (isInBin) menuItems.push({id: EActionType.Restore, name: t('actions.restore'), icon: 'Undo'});
  if (isInBin) menuItems.push({id: EActionType.Delete, name: t('actions.delete'), icon: 'Bin'});
  if (!isInBin) menuItems.push({id: EActionType.ToBin, name: t('actions.toBin'), icon: 'Bin'});

  const handleOnMenuAction = (action: EActionType) => {
    switch (action) {
      case EActionType.Rename: {
        setIsRenameOpen(true);
        break;
      }
      case EActionType.Download: {
        onExport().then();
        break;
      }
      case EActionType.Restore: {
        onRestore().then();
        break;
      }
      case EActionType.ToBin: {
        onDelete().then();
        break;
      }
      case EActionType.Delete: {
        setIsDeleteOpen(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Box boxShadow="shadow_1" position="relative" zIndex={2}>
      {isRenameOpen && (
        <ProjectRenameModal
          initialName={project.name || ''}
          onChange={(v) => onRename?.(v)}
          onClose={() => setIsRenameOpen(false)}
        />
      )}

      {isDeleteOpen && (
        <ProjectDeleteModal
          name={project.name || ''}
          onDelete={() => onDelete()}
          onClose={() => setIsDeleteOpen(false)}
        />
      )}

      <Flex bg="bg" h="48px" p="0 8px 0 0" justify="space-between">
        <Center gap="16px">
          <Box
            onClick={() => navigate(ROUTES.MyProjects)}
            _hover={{opacity: '0.8', cursor: 'pointer'}}
          >
            <Svg name="Favicon" size="s48" />
          </Box>
          <Box fontSize="lg" fontWeight="600" color="black" className="cut-text">
            {project.name}
          </Box>
          <Box>
            <SvgMenu
              items={menuItems}
              menuIcon={'More'}
              menuSize={'normal'}
              variant={'mediumWhite'}
              onSelect={handleOnMenuAction}
            />
          </Box>
        </Center>

        {children}
      </Flex>
    </Box>
  );
};

export const Header = observer(HeaderFC);
