/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CalculationErrorInfo} from './CalculationErrorInfo';
import {
  CalculationErrorInfoFromJSON,
  CalculationErrorInfoFromJSONTyped,
  CalculationErrorInfoToJSON
} from './CalculationErrorInfo';

/**
 *
 * @export
 * @interface ErrorInfoInner
 */
export interface ErrorInfoInner {
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  reason?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  entityType?: ErrorInfoInnerEntityTypeEnum | null;
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  entityUuid?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  entityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  entityTypeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  tabKey?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorInfoInner
   */
  fieldName?: string | null;
}

/**
 * @export
 */
export const ErrorInfoInnerEntityTypeEnum = {
  Model: 'model',
  Element: 'element',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type ErrorInfoInnerEntityTypeEnum =
  (typeof ErrorInfoInnerEntityTypeEnum)[keyof typeof ErrorInfoInnerEntityTypeEnum];

/**
 * Check if a given object implements the ErrorInfoInner interface.
 */
export function instanceOfErrorInfoInner(value: object): value is ErrorInfoInner {
  return true;
}

export function ErrorInfoInnerFromJSON(json: any): ErrorInfoInner {
  return ErrorInfoInnerFromJSONTyped(json, false);
}

export function ErrorInfoInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ErrorInfoInner {
  if (json == null) {
    return json;
  }
  return {
    reason: json['reason'] == null ? undefined : json['reason'],
    entityType: json['entity_type'] == null ? undefined : json['entity_type'],
    entityUuid: json['entity_uuid'] == null ? undefined : json['entity_uuid'],
    entityName: json['entity_name'] == null ? undefined : json['entity_name'],
    entityTypeName: json['entity_type_name'] == null ? undefined : json['entity_type_name'],
    tabKey: json['tab_key'] == null ? undefined : json['tab_key'],
    fieldName: json['field_name'] == null ? undefined : json['field_name']
  };
}

export function ErrorInfoInnerToJSON(value?: ErrorInfoInner | null): any {
  if (value == null) {
    return value;
  }
  return {
    reason: value['reason'],
    entity_type: value['entityType'],
    entity_uuid: value['entityUuid'],
    entity_name: value['entityName'],
    entity_type_name: value['entityTypeName'],
    tab_key: value['tabKey'],
    field_name: value['fieldName']
  };
}
