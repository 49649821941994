import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';
import {IEntityErrorMessage, JsFormName, Loader} from '@progress-fe/ui-kit';

import {TAdditionalTabModel, TJsonSchemaModel} from 'core/models';

import {IJsonSchemaTab, JsTabsForm} from './components';

interface IProps {
  uuid: string;
  name: string;
  isLoading?: boolean;
  jsonSchemas: Array<TJsonSchemaModel>;
  additionalTabs: Array<TAdditionalTabModel>;
  errors?: Array<IEntityErrorMessage>;
  onUpdateFormData: (
    uuid: string,
    schemaId: string,
    formData: unknown,
    tabCode?: string
  ) => Promise<void>;
  onToggleTab?: (uniqueCode: string) => Promise<void>;
}

const BaseJsFormsFC: FC<IProps> = ({
  uuid,
  name,
  errors,
  isLoading,
  jsonSchemas,
  additionalTabs,
  onUpdateFormData,
  onToggleTab
}) => {
  const tabs: IJsonSchemaTab[] = jsonSchemas.map((jsonSchema, index) => ({
    index: index,
    name: jsonSchema.name,
    jsonSchema
  }));

  return (
    <Box>
      <JsFormName name={name} />

      {isLoading ? (
        <Center p="100px 0 0">
          <Loader />
        </Center>
      ) : (
        <>
          {tabs.length > 0 && (
            <JsTabsForm
              tabs={tabs}
              errors={errors}
              additionalTabs={additionalTabs}
              onToggleTab={onToggleTab}
              onChange={(formData, tabIdx: number) => {
                onUpdateFormData(
                  uuid,
                  tabs[tabIdx].jsonSchema.id,
                  formData,
                  tabs[tabIdx].jsonSchema.tabCode
                ).then();
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export const BaseJsForms = observer(BaseJsFormsFC);
