export enum EProjectSortType {
  Asc = 'asc',
  Desc = 'desc'
}

export enum EProjectSortField {
  Name = 'name',
  Type = 'type',
  Deleted = 'deleted',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export enum ECalculationStatus {
  NoCalcResult = 'no_calc_result',
  CalcInProgress = 'calc_in_progress',
  CalcResultSuccess = 'calc_result_success',
  CalcResultError = 'calc_result_error',
  CalcResultPartialSuccess = 'calc_result_partial_success',
  UnknownDefaultOpenApi = '11184809'
}
