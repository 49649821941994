/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {RecycleHandle} from './RecycleHandle';
import {
  RecycleHandleFromJSON,
  RecycleHandleFromJSONTyped,
  RecycleHandleToJSON
} from './RecycleHandle';
import type {GraphZoneElementData} from './GraphZoneElementData';
import {
  GraphZoneElementDataFromJSON,
  GraphZoneElementDataFromJSONTyped,
  GraphZoneElementDataToJSON
} from './GraphZoneElementData';
import type {ElementData} from './ElementData';
import {ElementDataFromJSON, ElementDataFromJSONTyped, ElementDataToJSON} from './ElementData';
import type {RecycleData} from './RecycleData';
import {RecycleDataFromJSON, RecycleDataFromJSONTyped, RecycleDataToJSON} from './RecycleData';

/**
 *
 * @export
 * @interface Data
 */
export interface Data {
  /**
   *
   * @type {string}
   * @memberof Data
   */
  elementName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Data
   */
  templateCode?: DataTemplateCodeEnum;
  /**
   *
   * @type {string}
   * @memberof Data
   */
  elementModelName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Data
   */
  elementType?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Data
   */
  isTargetHandle?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Data
   */
  isSourceHandle?: boolean | null;
  /**
   *
   * @type {RecycleHandle}
   * @memberof Data
   */
  recycleHandle?: RecycleHandle;
}

/**
 * @export
 */
export const DataTemplateCodeEnum = {
  Default: 'default',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type DataTemplateCodeEnum = (typeof DataTemplateCodeEnum)[keyof typeof DataTemplateCodeEnum];

/**
 * Check if a given object implements the Data interface.
 */
export function instanceOfData(value: object): value is Data {
  return true;
}

export function DataFromJSON(json: any): Data {
  return DataFromJSONTyped(json, false);
}

export function DataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Data {
  if (json == null) {
    return json;
  }
  return {
    elementName: json['elementName'] == null ? undefined : json['elementName'],
    templateCode: json['templateCode'] == null ? undefined : json['templateCode'],
    elementModelName: json['elementModelName'] == null ? undefined : json['elementModelName'],
    elementType: json['elementType'] == null ? undefined : json['elementType'],
    isTargetHandle: json['isTargetHandle'] == null ? undefined : json['isTargetHandle'],
    isSourceHandle: json['isSourceHandle'] == null ? undefined : json['isSourceHandle'],
    recycleHandle:
      json['recycleHandle'] == null ? undefined : RecycleHandleFromJSON(json['recycleHandle'])
  };
}

export function DataToJSON(value?: Data | null): any {
  if (value == null) {
    return value;
  }
  return {
    elementName: value['elementName'],
    templateCode: value['templateCode'],
    elementModelName: value['elementModelName'],
    elementType: value['elementType'],
    isTargetHandle: value['isTargetHandle'],
    isSourceHandle: value['isSourceHandle'],
    recycleHandle: RecycleHandleToJSON(value['recycleHandle'])
  };
}
