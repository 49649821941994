import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Tabs, TabList, Tab, TabPanels, TabPanel} from '@chakra-ui/react';
import {Dot, IEntityErrorMessage, ITab, JsForm, SvgMenu} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {TAdditionalTabModel, TJsonSchemaModel} from 'core/models';

export interface IJsonSchemaTab extends ITab {
  jsonSchema: TJsonSchemaModel;
}

interface IProps<T> {
  tabs: IJsonSchemaTab[];
  additionalTabs: Array<TAdditionalTabModel>;
  errors?: Array<IEntityErrorMessage>;
  onChange: (formData: T, tabIdx: number, changedFieldPath?: string) => void;
  onToggleTab?: (uniqueCode: string) => Promise<void>;
}

const JsTabsFormFC = <T,>({tabs, errors, additionalTabs, onChange, onToggleTab}: IProps<T>) => {
  const {projectStore} = useStore();

  const [tabIndex, setTabIndex] = useState(0);

  const withTabs = useMemo(() => {
    return tabs.length > 1 || (tabs.length === 1 && additionalTabs.length > 0);
  }, [tabs.length, additionalTabs.length]);

  useEffect(() => {
    setTabIndex(projectStore?.uiState?.tabIndex || 0);
  }, [projectStore?.uiState?.tabIndex]);

  useEffect(() => {
    if (tabs.length > 0 && tabIndex > tabs.length - 1) {
      setTabIndex(0);
      projectStore?.selectTabIndex(0);
    }
  }, [tabIndex, tabs.length, projectStore]);

  const onSelectTab = (index: number) => {
    /* Skip click on the last "..." tab */
    if (additionalTabs.length === 0 || tabs.length - 1 >= index) {
      setTabIndex(index);
      projectStore?.selectTabIndex(index);
    }
  };

  return (
    <Tabs index={tabIndex} variant="soft-rounded" onChange={onSelectTab}>
      {withTabs && (
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.index} gap={'4px'} isDisabled={tab.isDisabled}>
              <span>{tab.name}</span>
              {errors?.some((e) => e.tabKey === tab.jsonSchema.id) && <Dot variant={'error'} />}
            </Tab>
          ))}
          {additionalTabs.length > 0 && (
            <Tab p={'4px 3px'} key={'AdditionalTab'}>
              <SvgMenu
                offset={[0, 8]}
                menuIcon={'More'}
                menuSize={'small'}
                variant={'compactGray'}
                items={additionalTabs.map((tab) => ({
                  name: tab.name,
                  id: tab.uniqueCode,
                  icon: tab.isActive ? 'Check' : undefined
                }))}
                onSelect={(value) => onToggleTab?.(value)}
              />
            </Tab>
          )}
        </TabList>
      )}

      <TabPanels>
        {tabs.map(({jsonSchema}, index) => (
          <TabPanel key={jsonSchema.id}>
            <JsForm
              schema={jsonSchema.schema}
              uiSchema={jsonSchema.uiSchema}
              formData={jsonSchema.formData as T}
              onChange={(formData, changedFieldPath) => {
                onChange(formData, index, changedFieldPath);
              }}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const JsTabsForm = observer(JsTabsFormFC);
