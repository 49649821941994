/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EdgeType = {
  Default: 'default',
  Recycle: 'recycle',
  Material: 'material',
  Energy: 'energy',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type EdgeType = (typeof EdgeType)[keyof typeof EdgeType];

export function instanceOfEdgeType(value: any): boolean {
  for (const key in EdgeType) {
    if (Object.prototype.hasOwnProperty.call(EdgeType, key)) {
      if ((EdgeType as Record<string, EdgeType>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function EdgeTypeFromJSON(json: any): EdgeType {
  return EdgeTypeFromJSONTyped(json, false);
}

export function EdgeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EdgeType {
  return json as EdgeType;
}

export function EdgeTypeToJSON(value?: EdgeType | null): any {
  return value as any;
}
