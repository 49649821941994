/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const JournalTaskRunPointEnum = {
  RunAt: 'run_at',
  CalculationNumber: 'calculation_number',
  Status: 'status',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type JournalTaskRunPointEnum =
  (typeof JournalTaskRunPointEnum)[keyof typeof JournalTaskRunPointEnum];

export function instanceOfJournalTaskRunPointEnum(value: any): boolean {
  for (const key in JournalTaskRunPointEnum) {
    if (Object.prototype.hasOwnProperty.call(JournalTaskRunPointEnum, key)) {
      if ((JournalTaskRunPointEnum as Record<string, JournalTaskRunPointEnum>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function JournalTaskRunPointEnumFromJSON(json: any): JournalTaskRunPointEnum {
  return JournalTaskRunPointEnumFromJSONTyped(json, false);
}

export function JournalTaskRunPointEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): JournalTaskRunPointEnum {
  return json as JournalTaskRunPointEnum;
}

export function JournalTaskRunPointEnumToJSON(value?: JournalTaskRunPointEnum | null): any {
  return value as any;
}
